<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="pb-0"
        cols="12"
        md="3"
        sm="12"
      >
        <v-row dense>
          <v-col
            class="pb-0"
            cols="12"
          >
            <div role="tablist">
              <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'mb-1']"
                no-body
              >
                <b-card-header
                  class="p-0"
                  header-tag="header"
                  role="tab"
                >
                  <v-list-item
                    @click="globalSettings = !globalSettings"
                    class="ma-0"
                    v-b-toggle.settings
                  >
                    <v-list-item-title
                      :class="
                        this.globalSettings
                          ? 'primary--text text-wrap'
                          : 'text-wrap'
                      "
                    >
                      {{ 'EventBee ' + $t('settings.lang_settings') }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon v-if="!this.globalSettings"
                        >keyboard_arrow_down</v-icon
                      >
                      <v-icon v-if="this.globalSettings"
                        >keyboard_arrow_up</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </b-card-header>
                <b-collapse
                  :visible="this.globalSettings"
                  accordion="settings"
                  id="settings"
                  role="tabpanel"
                >
                  <b-card-body class="pa-0">
                    <b-list-group flush>
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'generalInfo'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('generalInfo')"
                        class="items"
                      >
                        {{ $t('generic.lang_globalInformations') }}
                      </b-list-group-item>

                      <!--                      <b-list-group-item :class="this.currentTab === 'Gestelltungen'? 'primary&#45;&#45;text' : ''"
                                         @click="handleClick('Gestelltungen')"
                                         class="items">
                        {{'Gestelltung'}}
                      </b-list-group-item>
                      -->
                      <!--                      <b-list-group-item :class="this.currentTab === 'design'? 'primary&#45;&#45;text' : ''"
                                         @click="handleClick('design')"
                                         class="items">
                        {{'Design'}}
                      </b-list-group-item>-->

                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'eventGroup'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('eventGroup')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_eventGroups') }}
                      </b-list-group-item>
                      
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'seatingmaps'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('seatingmaps')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_seatingMaps') }}
                      </b-list-group-item>

                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'priceSegments'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('priceSegments')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_priceSegments') }}
                      </b-list-group-item>
                      
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'priceCategories'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('priceCategories')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_seatCategories') }}
                      </b-list-group-item>

                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'attributes'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('attributes')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_attributeGroups') }}
                      </b-list-group-item>

                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'pools'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('pools')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_pools') }}
                      </b-list-group-item>

                      <!--                      <b-list-group-item :class="this.currentTab === 'settings'? 'primary&#45;&#45;text' : ''"
                                         @click="handleClick('settings')"
                                         class="items">
                        {{$t('settings.lang_settings')}}
                      </b-list-group-item>-->

                      <!--                      <b-list-group-item :class="this.currentTab === 'preview'? 'primary&#45;&#45;text' : ''"
                                         @click="handleClick('preview')"
                                         class="items">
                        {{$t('generic.lang_preview')}}
                      </b-list-group-item>-->

                      <!--                      <b-list-group-item :class="this.currentTab === 'help'? 'primary&#45;&#45;text' : ''"
                                                               @click="handleClick('help')"
                                                               class="items">
                                              {{$t('generic.lang_nav_help')}}
                                            </b-list-group-item>-->
                    </b-list-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </v-col>
          <v-col
            class="pb-0"
            cols="12"
          >
            <div role="tablist">
              <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'mb-1']"
                no-body
              >
                <b-card-header
                  class="p-0"
                  header-tag="header"
                  role="tab"
                >
                  <v-list-item
                    @click="globalSettings = !globalSettings"
                    class="ma-0"
                    v-b-toggle.preSettings
                  >
                    <v-list-item-title
                      :class="
                        this.globalSettings
                          ? 'primary--text text-wrap'
                          : 'text-wrap'
                      "
                    >
                      {{ $t('generic.lang_otherSettings') }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon v-if="!this.globalSettings"
                        >keyboard_arrow_down</v-icon
                      >
                      <v-icon v-if="this.globalSettings"
                        >keyboard_arrow_up</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </b-card-header>
                <b-collapse
                  :visible="this.globalSettings"
                  accordion="preSettings"
                  id="preSettings"
                  role="tabpanel"
                >
                  <b-card-body class="pa-0">
                    <b-list-group flush>
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'customerSelfService'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('customerSelfService')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_customerSelfService') }}
                      </b-list-group-item>

                      <b-list-group-item
                        :class="
                          this.currentTab === 'payment' ? 'primary--text' : ''
                        "
                        @click="handleClick('payment')"
                        class="items"
                      >
                        {{ $t('generic.lang_zahlungsoptionen') }}
                      </b-list-group-item>

                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'customerNotifications'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('customerNotifications')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_customerNotifications') }}
                      </b-list-group-item>

                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'emailConfig'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('emailConfig')"
                        class="items"
                      >
                        {{ $t('settings.lang_emailSettings') }}
                      </b-list-group-item>
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'emailTemplates'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('emailTemplates')"
                        class="items"
                      >
                        {{ $t('generic.lang_answerEmailTemplates') }}
                      </b-list-group-item>
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'refundPolicies'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('refundPolicies')"
                        class="items"
                      >
                        {{ $t('eventbee.lang_refundPolicies') }}
                      </b-list-group-item>
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'dynamicContent'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('dynamicContent')"
                        class="items"
                      >
                        {{ $t('generic.lang_dynamicContent') }}
                      </b-list-group-item>
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'predefinedTicket'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('predefinedTicket')"
                        class="items"
                      >
                        {{ $t('generic.lang_ticketCodes') }}
                      </b-list-group-item>
                      <b-list-group-item
                        :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'globalSettings'
                            ? 'primary--text'
                            : '',
                        ]"
                        @click="handleClick('globalSettings')"
                        class="items"
                      >
                        {{ $t('generic.lang_globalSettings') }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="9"
        sm="12"
      >
        <div v-if="currentTab === 'generalInfo'">
          <GeneralInfoComponent />
        </div>

        <div v-if="currentTab === 'times'"></div>

        <div v-if="currentTab === 'seatingmaps'">
          <SeatingMapsComponent/>
        </div>

        <div v-if="currentTab === 'design'"></div>

        <div v-if="currentTab === 'payment'"></div>

        <div v-if="currentTab === 'eventGroup'">
          <EventGroupsComponent />
        </div>

        <div v-if="currentTab === 'products'"></div>

        <div v-if="currentTab === 'settings'"></div>

        <div v-if="currentTab === 'preview'"></div>

        <div v-if="currentTab === 'help'"></div>

        <div v-if="currentTab === 'priceSegments'">
          <PriceSegmentsComponent />
        </div>
        
        <div v-if="currentTab === 'priceCategories'">
          <SeatCategoriesComponent/>
        </div>

        <div v-if="currentTab === 'attributes'">
          <AttributeGroupsComponent />
        </div>

        <div v-if="currentTab === 'pools'">
          <EventbeePoolsComponent />
        </div>

        <div v-if="currentTab === 'customerSelfService'">
          <CustomerSelfServiceComponent />
        </div>

        <div v-if="currentTab === 'payment'">
          <eventbee-payment-option-component></eventbee-payment-option-component>
        </div>

        <div v-if="currentTab === 'customerNotifications'">
          <CustomerNotificationsComponent />
        </div>

        <div v-if="currentTab === 'emailConfig'">
          <EventbeeEmailConfigComponent />
        </div>
        <div v-if="currentTab === 'emailTemplates'">
          <EmailTemplatesComponent />
        </div>
        <div v-if="currentTab === 'refundPolicies'">
          <RefundPoliciesComponent />
        </div>
        <div v-if="currentTab === 'dynamicContent'">
          <PrivacyAGBConditionsComponent />
        </div>
        <div v-if="currentTab === 'predefinedTicket'">
          <PredefinedTicketCodesComponent />
        </div>
        <div v-if="currentTab === 'globalSettings'">
          <EventbeeGlobalSettingsComponent/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PriceSegmentsComponent from './PriceSegmentsComponent';
import EventbeePoolsComponent from './EventbeePoolsComponent';
import EventGroupsComponent from './EventGroupsComponent';
import EmailTemplatesComponent from './EmailTemplatesComponent';
import GeneralInfoComponent from '../../eventbee/settings/GeneralInfoComponent';
import RefundPoliciesComponent from './RefundPoliciesComponent';
import PrivacyAGBConditionsComponent from './PrivacyAGBConditionsComponent';
import EventbeeEmailConfigComponent from './EventbeeEmailConfigComponent';
import EventbeePaymentOptionComponent from './EventbeePaymentOptionComponent';
import AttributeGroupsComponent from "./AttributeGroupsComponent";
import EventbeeGlobalSettingsComponent from "./EventbeeGlobalSettingsComponent";
import CustomerNotificationsComponent from './CustomerNotificationsComponent';
import PredefinedTicketCodesComponent from './PredefinedTicketCodesComponent.vue';
import SeatingMapsComponent from './SeatingMapsComponent.vue';
import SeatCategoriesComponent from './SeatCategoriesComponent.vue';
import CustomerSelfServiceComponent from './CustomerSelfServiceComponent.vue';

export default {
  name: 'EventbeeSettingsComponent',
  components: {
    EventbeeGlobalSettingsComponent,
    AttributeGroupsComponent,
    EventbeeEmailConfigComponent,
    PrivacyAGBConditionsComponent,
    RefundPoliciesComponent,
    GeneralInfoComponent,
    EmailTemplatesComponent,
    EventGroupsComponent,
    PriceSegmentsComponent,
    EventbeePaymentOptionComponent,
    EventbeePoolsComponent,
    CustomerNotificationsComponent,
    PredefinedTicketCodesComponent,
    SeatingMapsComponent,
    SeatCategoriesComponent,
    CustomerSelfServiceComponent
  },
  data() {
    return {
      text: 'example text',
      currentTab: 'generalInfo',
      globalSettings: '',
      // ----------------------------------
    };
  },
  computed: {
    tabs: function () {
      return [
        {
          title: this.$t('generic.lang_basicData'),
          value: 'generalInfo',
        },
        {
          title: 'Logos',
          value: 'logos',
        },
      ];
    },
  },
  methods: {
    handleClick(newTab) {
      if (newTab && newTab.length > 0) {
        this.currentTab = newTab;
        this.$router.push({query: {tab: newTab}})
      }
    },
  },
  mounted() {
    this.handleClick(this.$route.query.tab)
  }
};
</script>

<style scoped>
.tabs > .tabs__item,
.tabs {
  width: auto !important;
  z-index: 1 !important;
}

.tabs__item_active,
.tabs__active-line,
.shadow-tabs .tab-item-line {
  padding-top: 20px !important;
}

.tabs__item {
  z-index: 1 !important;
}

.tabs__item:hover {
  border: none;
  outline: none;
}

.tabs__item:focus {
  border: none;
  outline: none;
}

.items {
  cursor: pointer !important;
}
</style>
