<template>
  <b-card no-body class="mb-1 transparent">
    <v-toolbar elevation="0" max-height="50" class="transparent">
      <v-tabs v-model="tab" height="50">
        <v-tab>
          {{ $t("generic.lang_general") }}
        </v-tab>
        <v-tab>
          {{ $t("eventbee.lang_walletPasses") }}
        </v-tab>
        <v-spacer></v-spacer>
        <v-btn
          v-if="tab == 0 || (tab == 1 && isSetup)"
          depressed
          color="success"
          @click="update"
          :disabled="loading"
          :loading="loading"
        >
          {{ $t("generic.lang_save") }}
        </v-btn>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item class="px-5 pt-5">
        <v-form ref="smsForm" v-model="validSms">
          <v-checkbox
            :label="$t('eventbee.lang_enableCustomerServicePage')"
            v-model="eb_enableCustomerServicePage"
          />

          <v-checkbox
            :label="$t('eventbee.lang_allowCustomerToDownloadTickets')"
            v-model="eb_allowCustomerDownloadTickets"
          />

          <v-checkbox
            :label="$t('eventbee.lang_allowCustomerToVoidTickets')"
            v-model="eb_allowCustomerVoidTickets"
          />
        </v-form>
      </v-tab-item>

      <v-tab-item class="px-5 py-5">
        <v-form ref="emailForm" v-model="validEmail">
          <v-alert width="100%" outlined type="info">
            {{ $t("eventbee.lang_walletPassesHeaderText") }}
          </v-alert>

          <v-btn
            v-if="!isSetup"
            large
            class="ml-0 mb-3"
            color="info"
            :loading="loading"
            @click="isSetup = !isSetup"
          >
            {{ $t("eventbee.lang_setup") }}
          </v-btn>

          <div v-else>
            <v-checkbox
              :label="$t('eventbee.lang_enableWalletPass')"
              v-model="eb_enableWalletPass"
            />

            <v-checkbox
              :label="$t('eventbee.lang_sendWalletPassInEmail')"
              v-model="eb_sendWalletPassInEmail"
            />

            <v-checkbox
              :label="
                $t(
                  'eventbee.lang_letCustomerDownloadWalletPassesInCustomerSelsServicePage'
                )
              "
              v-model="eb_letCustomerDownloadWalletPasses"
            />

            <v-btn
              large
              class="ml-0"
              color="success"
              :loading="loading"
              @click="isSetup = !isSetup"
            >
              {{ $t("generic.lang_cancel") }}
            </v-btn>
          </div>
        </v-form>
      </v-tab-item>
    </v-tabs-items>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </b-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  name: "CustomerSelfServiceComponent",
  mixins: [mixin],

  data() {
    return {
      loading: false,
      tab: 0,
      validSms: false,
      validEmail: false,
      isSetup: false,

      eb_enableCustomerServicePage: false,
      eb_allowCustomerDownloadTickets: false,
      eb_allowCustomerVoidTickets: false,

      eb_enableWalletPass: false,
      eb_sendWalletPassInEmail: false,
      eb_letCustomerDownloadWalletPasses: false,
    };
  },

  methods: {
    update() {
      if (this.tab === 0) {
        this.updateGeneral();
      } else if (this.tab === 1) {
        this.updateWalletPasses();
      }
    },
    updateGeneral() {
      if (!this.$refs.smsForm.validate()) return;
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.EVENTBEE.SETTINGS.CUSTOMER_SELF_SERVICE
            .UPDATECUSTOMERSERVICE,
          {
            eb_enableCustomerServicePage: this.eb_enableCustomerServicePage,
            eb_allowCustomerDownloadTickets:
              this.eb_allowCustomerDownloadTickets,
            eb_allowCustomerVoidTickets: this.eb_allowCustomerVoidTickets,
          }
        )
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateWalletPasses() {
      if (!this.$refs.emailForm.validate()) return;
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.EVENTBEE.SETTINGS.CUSTOMER_SELF_SERVICE.UPDATEWALLETPASSES,
          {
            eb_enableWalletPass: this.eb_enableWalletPass,
            eb_sendWalletPassInEmail: this.eb_sendWalletPassInEmail,
            eb_letCustomerDownloadWalletPasses:
              this.eb_letCustomerDownloadWalletPasses,
          }
        )
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getGeneral() {
      this.axios
        .post(
          ENDPOINTS.EVENTBEE.SETTINGS.CUSTOMER_SELF_SERVICE.GETCUSTOMERSERVICE
        )
        .then((res) => {
          if (res.status === 200) {
            this.eb_enableCustomerServicePage =
              res.data.eb_enableCustomerServicePage;
            this.eb_allowCustomerDownloadTickets =
              res.data.eb_allowCustomerDownloadTickets;
            this.eb_allowCustomerVoidTickets =
              res.data.eb_allowCustomerVoidTickets;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },
    getWalletPasses() {
      this.axios
        .post(
          ENDPOINTS.EVENTBEE.SETTINGS.CUSTOMER_SELF_SERVICE.GETEWALLETPASSES
        )
        .then((res) => {
          if (res.status === 200) {
            this.eb_enableWalletPass = res.data.eb_enableWalletPass;
            this.eb_sendWalletPassInEmail = res.data.eb_sendWalletPassInEmail;
            this.eb_letCustomerDownloadWalletPasses =
              res.data.eb_letCustomerDownloadWalletPasses;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },
  },
  mounted() {
    this.getGeneral();
    this.getWalletPasses();
  },
};
</script>

<style>
.v-input--checkbox .v-input__slot label {
  margin-bottom: 0%;
}
</style>
